import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import * as newColors from 'src/styles/newColors'

export const ContaPj100GratuitaSection = styled.section`
  background-color: ${newColors.orange.clay};
  padding: 40px 0;

  @media ${device.desktopLG} {
    padding: 56px 0;
  }

  .section_wrapper{
    &__content {

      @media ${device.tablet} {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      @media ${device.desktopLG} {
        justify-content: space-around;
      }
      
      &__image {
        width: 100%;
        height: auto;
        margin-bottom: 24px;

        @media ${device.tablet} { 
          width: 40%;
          margin: 0;
        }

        @media ${device.desktopLG} {
          width: 48%;
        }

        @media ${device.desktopXXL} {
          width: 43%;
        }
      }
    
      &__texts {

        @media ${device.tablet} {
          width: 55%;
        }

        @media ${device.desktopLG} {
          width: 40%;
        }
        
        @media ${device.desktopXXL} {
          width: 32% ;
        }
        
        &__title {
          font-family: "Citrina", Helvetica, sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: ${newColors.sand};
          letter-spacing: 0.7px;
          width: 60%;
          margin: 0;

          @media ${device.tablet} {
            font-size: 28px;
            line-height: 34px;
            width: 75%;
          }

          @media ${device.desktopLG} {
            font-size: 36px;
            line-height: 42px;
            width: 100%;
          }
        }
        
        &__description {
          font-family: "Inter", Helvetica, sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: ${newColors.sand};
          margin: 24px 0;

          @media ${device.desktopLG} {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
  }
`
