import { device } from 'src/styles/breakpoints'
import * as newColors from 'src/styles/newColors'
import styled from 'styled-components'

export const AcompanheNossasRedes = styled.section`
  background-color: ${newColors.orange.dark};
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 45px 0;
  }

  @media ${device.desktopXXL} {
    padding: 136px 0
  }

  .section_wrapper {
    &__content {

      @media ${device.tablet} { 
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      @media ${device.desktopLG} {
        align-items: flex-end;
      }

      @media ${device.desktopXXL} {
        position: relative;
        justify-content: flex-end;
      }

      &__graphism {
        display: flex;
        justify-content: flex-end;
        
        @media ${device.tablet} {
          width: 49%;
          height: fit-content;
          justify-content: center;
          align-items: center;
        }

        @media ${device.desktopLG} {
          width: 36%;
        }

        @media ${device.desktopXXL} {
          position: absolute;
          width: 30%;

          top: -43%;
          right: -10%;

          max-width: 350px;
        }

        &__source {
          width: 54%;
          height: auto;

          @media ${device.tablet} {
            width: 100%;
          }
        }
      }
      
      &__texts {
        @media ${device.tablet} {
          width: 44%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        @media ${device.desktopLG} {
          width: 45%;
        }

        @media ${device.desktopXXL} {
          flex-direction: row; 
          width: 75%;
        }

        &__article {
          &--0 {
            margin: 16px 0;

            @media ${device.tablet} {
              margin: 0 0 32px 0;
            }

            @media ${device.desktopXXL} {
              margin: 0;
            }

            @media ${device.desktopXXL} {
              width: 42%;
            }
          }

          &--1 {
            @media ${device.desktopXXL} {
              width: 45%;
            }
          }
          
          &__title {
            color: white;
            font-family: "Citrina", Helvetica, sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            margin: 16px 0;

            @media ${device.tablet} {
              font-size: 24px;
              line-height: 29px;
            }

            @media ${device.desktopLG} {
              font-size: 28px;
              line-height: 34px;
              margin: 32px 0;
            }

            @media ${device.desktopXXL} {
              font-size: 28px;
              line-height: 34px;
            }
          }
        } 
      }
    }
  }
`
