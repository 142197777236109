import React from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import './styles.scss'

type IconGreenBgProps = {
  iconClassName?: string;
  containerClassName?: string;
  icon: string;
  iconPath: string;
  marginRight?: '3' | '4' | '5';
}

const IconGreenBg = ({
  iconClassName,
  containerClassName,
  icon,
  iconPath,
  marginRight,
}: IconGreenBgProps) => {
  return (
    <div className={`icon_green_bg_wrapper ${marginRight && `pr-${marginRight}` } ${containerClassName}`}>
      <IconsSwitch
        className={`mr-${marginRight} ${iconClassName} icon_green_bg_wrapper__icon`}
        icon={icon}
        customPath={iconPath}
        lib='interco'
        width='16'
        height='16'
        color='black'
      />
    </div>
  )
}
export default IconGreenBg
