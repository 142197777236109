import { device } from "src/styles/breakpoints"
import * as newColors from "src/styles/newColors"
import styled from "styled-components"

export const EventRegisterFormWrapper = styled.div`
  
  @keyframes slideRightToCenter {
    from {
      transform: translate(100vw, 0);
    }

    to {
      transform: translate(0, 0);
    }
  }

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 48px;
  min-height: 100vh;
  background-color: white;
  animation: slideRightToCenter 0.5s ease-in-out forwards;

  @media ${device.tablet} {
    width: 640px;
    height: fit-content;
    min-height: fit-content;
    margin: auto;
    border-radius: 24px;
    animation: slideRightToCenter 0.7s ease-in-out forwards;
  }

  .section_wrapper {

    &__title {
      font-family: "Inter", Helvetica, sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px ;
      color: ${newColors.graphite};
      margin: 24px 0;
      width: 100%;

      @media ${device.tablet} {
        font-size: 24px;
        line-height: 29px;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
  
      &__active_cnpj {
        margin-bottom: 12px;

        &__label {
          color: ${newColors.graphite};
          font-family: "Inter", Helvetica, sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        &__radios {
          display: flex;
          flex-direction: row;

          &--no {
            margin-left: 12px;
          }
        }
      }
  
      &__accept_terms {
        margin-top: 24px;
      }
  
      &__subscribe {
        margin-top: 24px;
      }
    }
  }
`

export const SubmitFeedbackScreen = styled.div`
  width: 100%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    width: 75%;
    margin: auto;
  }

  .feedback_screen {

    &__title, &__description {
      font-family: "Inter", Helvetica, sans-serif;
      color: ${newColors.graphite};
      text-align: center;
    }

    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      margin: 24px 0;
    }

    &__description {
      font-weight: 400;
      font-size: 18;
      line-height: 22px;
      margin-bottom: 24px;
    }
  }
`
