import styled from "styled-components"

import { device } from "src/styles/breakpoints"
import * as newColors from 'src/styles/newColors'

export const ValeAPenaVerDeNovoSection = styled.section`
  background-color: ${newColors.primary[100]} ;
  padding: 40px 0;

  @media ${device.desktopLG} {
    padding: 56px 0;
  }

  .section_wrapper{
    &__content {

      @media ${device.tablet} {
        display: flex;
        align-items: center;
        justify-content: space-around;  
      }

      @media ${device.desktopLG} {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 0px 24px;
      }

      @media ${device.desktopXXL} {
        justify-content: space-around;
      }
      
      &__video_thumbnail {
        display: flex;
        width: 100%;

        @media ${device.tablet}{
          width: 47%;
        }

        @media ${device.desktopLG} {
          width: 55%;
        }

        @media ${device.desktopXXL}{
          width: 46%;
        }
        
        &__source {
          width: 100%;
          height: auto;
        }
      }

      &__texts{
        width: 100%;

        @media ${device.tablet} {
          width: 41%;
        }

        @media ${device.desktopLG} {
          width: 30%;
        }

        @media ${device.desktopXXL} {
          width: 25%;
        }

        &__title, &--highlighted{
          font-family: 'Citrina', Helvetica, sans-serif;
          font-size: 24px;
          line-height: 29px;
          font-weight: 500;
          color: ${newColors.sand};

          @media ${device.tablet} {
            font-size: 28px;
            line-height: 34px;
          }

          @media ${device.desktopLG} {
            font-size: 40px;
            line-height: 44px;
          }
        }

        &__title{
          color: ${newColors.orange.dark};
          margin: 16px 0;

          @media ${device.tablet} {
            margin: 0 0 16px 0;
          }

          @media ${device.desktopXXL} {
            margin-bottom: 32px;
          }

          &--highlighted{
            color: ${newColors.primary[500]};
            display: inline-flex;

            @media ${device.desktopLG} {
              padding-left: 4px;
              display: inline;
            }
          }
        }
      }
    }
  }
`
